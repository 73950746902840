export default [
  {
    title: 'Home',
    route: 'partner-home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth'
  },
  {
    icon: 'UserIcon',
    action: 'read',
    resource: 'Auth',
    children:[
      { 
        title: 'Listar Usuários',
        icon: 'ListIcon',
        route: 'partner-clients-list',
        action: 'read',
        resource: 'Auth'
      },
    ]
  },
 
]
